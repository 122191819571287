import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('MultiButtonController', MultiButtonController);

// Injection of each class
MultiButtonController.$inject = ['$scope', 'ScopeAndLocal', 'Auth', 'SCS'];

// Controller Constructor
function MultiButtonController($scope, ScopeAndLocal, Auth, SCS) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            vm.auth = Auth;
            vm.scanStationService = SCS;

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            $scope.scanStation = vm.scanStation;

            ScopeAndLocal.startVariable(vm, $scope, 'field');
            $scope.field = vm.field;

            ScopeAndLocal.startVariable(vm, $scope, 'data');
            $scope.data = vm.data;

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            $scope.main = vm.main;

            ScopeAndLocal.startVariable(vm, $scope, 'row');
            $scope.row = vm.row;

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            $scope.fc = vm.fc;

            ScopeAndLocal.startVariable(vm, $scope, 'last');
            $scope.last = vm.last;

        } catch (e) {
            console.log(e);
        }
    }

}

export default MultiButtonController;