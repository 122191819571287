export type LinkDataElements = {
    uniqueModuleSlug: string;
    stationId: string;
    urlFieldSlugData: string;
    href: string;
};

import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('linkField', LinkField);

/**
 * Service constructor.
 * @constructor
 */
function LinkField() {

    let service = this;
    service.linkData = {}

    /**
     * This is the init rule for this field, this will set the main
     * parameters that will be showing at the start of the field.
     *
     * @param field
     * @param scanStation
     * @param linkType
     * @param $state
     */
    service.initBlocLink = (field, scanStation, linkType, $state) => {

        // starting a new object in case that the object was clean before
        if (service.linkData[field.field_slug] == undefined) {

            // loading as type
            let linkData: LinkDataElements = {
                uniqueModuleSlug: '',
                stationId: '',
                urlFieldSlugData: '',
                href: '',
            }
            service.linkData[field.field_slug] = linkData
        }

        // loading the unique module slug
        service.linkData[field.field_slug].uniqueModuleSlug = field.dropdownFromStationLookUp != undefined && field.dropdownFromStationLookUp.uniqueModuleSlug != null
            ? field.dropdownFromStationLookUp.uniqueModuleSlug : '';

        // getting the scanStation ID
        service.linkData[field.field_slug].stationId = field.dropdownFromStationLookUp != undefined && field.dropdownFromStationLookUp.stationId != null
            ? field.dropdownFromStationLookUp.stationId : '';


        // by the field type we are going to set the field url
        switch (linkType[field.field_slug]) {

            case 'bloc-link':
                service.linkData[field.field_slug].href = $state.href('bloc', service.linkData[field.field_slug])
                break;

            case 'custom-url-with-json':
                service.linkData[field.field_slug].href = field.linkDataCustomUrl;
                break;

            case 'custom-url-with-normal-parameters':

                if (scanStation.linkedDataFieldJson != null && scanStation.linkedDataFieldJson['key_' + field.id] != null) {
                    service.linkData[field.field_slug].href = field.linkDataCustomUrl + '?' + scanStation.linkedDataFieldJson['key_' + field.id];
                } else {
                    service.linkData[field.field_slug].href = field.linkDataCustomUrl;
                }

                break;

            default :
                console.log(`missing case for the linkData ${linkType[field.field_slug]}`)

        }

        service.appendReturnDetails(field, scanStation, null);
        service.appendFilterDataOptions(field, scanStation, null);
    }

    service.appendFilterDataOptions = (field, scanStation, href = null) => {
        if (field.onlyFilterDataInDestinationBloc == 1) {
            let baseUrl = '';

            if (href == null) {
                baseUrl = service.linkData[field.field_slug].href;
            } else {
                baseUrl = href;
            }
            let params = {
                fieldSlugsFilterOnly: "true"
            };

            if (href == null) {
                service.linkData[field.field_slug].href = service.appendQueryParams(baseUrl, params, href);
            } else {
                return service.appendQueryParams(baseUrl, params, href);
            }
        } else {
            if (href != null) {
                return href;
            }
        }
    }

    service.appendReturnDetails = (field, scanStation, href = null) => {
        if (field.returnToThisBlocAfterSubmit) {
            let baseUrl = '';
            if (href == null) {
                baseUrl = service.linkData[field.field_slug].href;
            } else {
                baseUrl = href;
            }

            let redirectToUniqueModuleSlug = scanStation.uniqueModuleSlug;
            let redirectToScanStationId = scanStation.scanStationObj.id;
            let params = {
                redirectToUniqueModuleSlug: redirectToUniqueModuleSlug,
                redirectToScanStationId: redirectToScanStationId
            };

            if (href == null) {
                service.linkData[field.field_slug].href = service.appendQueryParams(baseUrl, params, href);
            } else {
                return service.appendQueryParams(baseUrl, params, href)
            }
        } else {
            if (href != null) {
                return href;
            }
        }
    }

    service.appendQueryParams = (baseUrl, params, href) => {
        let query = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');

        return baseUrl + (baseUrl.includes('?') ? '&' : '?') + query;
    }

    /**
     * This will search in the urlFieldSlugData and
     * will return the right data to set or
     * false if we cant find any parameter:
     * case 1 : if(urlFieldSlugData.includes('{')) => checks inside an object
     * case 2: else => check for the string case
     *
     * @param urlFieldSlugData
     */
    service.getUrlFieldSlugDataToAdd = (urlFieldSlugData) => {

        let urlFieldSlugDataToAdd = false;

        // checking that we have an object inside of the string urlFieldSlugData
        if (typeof urlFieldSlugData != 'object' && urlFieldSlugData.includes('{')) {
            if (Object.keys(JSON.parse(urlFieldSlugData)).length > 0) {
                urlFieldSlugDataToAdd = urlFieldSlugData;
            }
        } else {
            // this is the case that we are getting the parameter without being an object,
            // like: 'text_field=aa&plain_field=Default+Value&multi_button=undefined'
            if (urlFieldSlugData != '') {
                urlFieldSlugDataToAdd = urlFieldSlugData;
            }
        }

        return urlFieldSlugDataToAdd;
    }

    /**
     * This will know how to build the url, here is set the
     * whole rule about getting the href of an element.
     *
     * @param field
     * @param urlFieldSlugData
     * @param urlParamName
     */
    service.buildUrl = (field, urlFieldSlugData, urlParamName = undefined) => {

        let href = [];
        let urlFieldSlugDataToAdd;

        // checking if we have a custom link
        if (field.linkDataCustomUrl != undefined) {
            href = [...href, field.linkDataCustomUrl]
        }

        // loads the url field slug data or false if we do not have any parameter
        urlFieldSlugDataToAdd = service.getUrlFieldSlugDataToAdd(urlFieldSlugData);

        // this will add only if we can find parameters on urlFieldSlugDataToAdd
        if (urlFieldSlugDataToAdd) {
            href = urlParamName != undefined
                ? [...href, `?${urlParamName}=${urlFieldSlugDataToAdd}`]
                : [...href, `?${urlFieldSlugDataToAdd}`];
        }

        return href.join('')
    }

    /**
     * This will load the href of a link element.
     *
     * @param field
     * @param scanStation
     * @param $state
     */
    service.loadBlocLink = (field, scanStation, linkType, $state) => {

        // starting a new object in case that the object was clean before
        if (service.linkData[field.field_slug] == undefined) {

            // loading as type
            let linkData: LinkDataElements = {
                uniqueModuleSlug: '',
                stationId: '',
                urlFieldSlugData: '',
                href: '',
            }
            service.linkData[field.field_slug] = linkData
        }

        // loading the unique module slug
        service.linkData[field.field_slug].uniqueModuleSlug = field.dropdownFromStationLookUp != undefined && field.dropdownFromStationLookUp.uniqueModuleSlug != null
            ? field.dropdownFromStationLookUp.uniqueModuleSlug : '';

        // getting the scanStation ID
        service.linkData[field.field_slug].stationId = field.dropdownFromStationLookUp != undefined && field.dropdownFromStationLookUp.stationId != null
            ? field.dropdownFromStationLookUp.stationId : '';

        // getting the field data
        if (scanStation.linkedDataFieldJson != null) {
            service.linkData[field.field_slug].urlFieldSlugData = scanStation.linkedDataFieldJson['key_' + field.id]
                ? scanStation.linkedDataFieldJson['key_' + field.id] : '';
        }

        // by the field type we are going to set the field url
        switch (linkType[field.field_slug]) {

            case 'bloc-link':
                // this will be removing the urlFieldSlug data if cant find a parameter here
                if (service.buildUrl(field, service.linkData[field.field_slug].urlFieldSlugData) == false) {
                    delete service.linkData[field.field_slug].urlFieldSlugData;
                }
                service.linkData[field.field_slug].href = $state.href('bloc', service.linkData[field.field_slug])
                break;

            case 'custom-url-with-json':
                service.linkData[field.field_slug].href = service.buildUrl(field, service.linkData[field.field_slug].urlFieldSlugData, 'urlFieldSlugData');
                break;

            case 'custom-url-with-normal-parameters':
                service.linkData[field.field_slug].href = service.buildUrl(field, service.linkData[field.field_slug].urlFieldSlugData);
                break;

            default :
                console.log(`missing case for the linkData ${linkType[field.field_slug]}`)
        }

        service.appendReturnDetails(field, scanStation, null);
        service.appendFilterDataOptions(field, scanStation, null);

    }

    /**
     * Method that will load a linkData from the
     * link service.
     *
     * @param fieldSlug
     */
    service.getLinkData = (fieldSlug) => {

        let linkData: LinkDataElements = {
            uniqueModuleSlug: '',
            stationId: '',
            urlFieldSlugData: '',
            href: '',
        }

        if (service.linkData[fieldSlug] != undefined) {
            linkData = service.linkData[fieldSlug];
        }

        return linkData
    }

    /**
     * Getting field Href.
     *
     * @param fieldSlug
     */
    service.getFieldHref = (fieldSlug) => {

        let href = '';

        if (service.linkData[fieldSlug] != undefined) {
            href = service.linkData[fieldSlug].href;
        }

        return href
    }

    /**
     * This will return all link data object.
     */
    service.getAllLinkData = () => {
        return service.linkData
    }

    /**
     * This will load the initial parameters.
     */
    service.getInitialParams = function () {
        return {
            'dropdownFromStationLookUp': {
                'validation': {
                    'required': false,
                }
            },
            'buttonText': {
                'validation': {
                    'required': false,
                }
            },
            'opensInNewTab': {
                'validation': {
                    'required': false,
                }
            },
            'buttonColour': {
                'validation': {
                    'required': false,
                }
            },
            'fieldLinkArray': {
                'validation': {
                    'required': false,
                }
            },
            'linkDataCustomUrl': {
                'validation': {
                    'required': false,
                }
            },
            'useStandardUrlParameters': {
                'validation': {
                    'required': false,
                }
            },
            'returnToThisBlocAfterSubmit': {
                'validation': {
                    'required': false,
                }
            },
            'onlyFilterDataInDestinationBloc': {
                'validation': {
                    'required': false,
                }
            }
        };
    }

}