"use strict";

import * as angular from 'angular';
import { LinkDataElements } from 'components/field/components/linkData/services/link.service'

angular.module('FieldModule')
    .controller('LinkController', LinkController);

// Injection of each class
LinkController.$inject = ['$scope', 'ScopeAndLocal', 'LinkFieldFactory', '$state', 'linkField', 'scanStation'];

// Controller Constructor
function LinkController($scope, ScopeAndLocal, LinkFieldFactory, $state, linkField, scanStationService) {

    let vm = this;
    vm.title = "Link Data Field";

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');

            vm.linkFactory = LinkFieldFactory
            vm.linkDataService = linkField;
            vm.fc.linkData = { }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be responsible to add a new field link.
     *
     * @param field
     */
    vm.addNewFieldLink = async (field) => {
        vm.fc.initialFieldParams.fieldLinkArray = await LinkFieldFactory.addNewFieldLink(vm.fc.initialFieldParams.fieldLinkArray, field);
        $scope.$apply();
    }

    /**
     * This will be responsible to remove data rom the fieldLinkArray.
     *
     * @param field
     */
    vm.removeFieldLink = async (index) => {
        vm.fc.initialFieldParams.fieldLinkArray = await LinkFieldFactory.removeFieldLink(vm.fc.initialFieldParams.fieldLinkArray, index);
        $scope.$apply();
    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    /*
    method to build the json for the link
     */
    vm.buildJsonForSingleRecord = async (field, row, scaStation) => {
        try
        {
            let fieldId = field.id;
            vm.linkFactory.loadLinkData(vm.scanStation.scanStationObj.scanStationFieldIdsToField, fieldId, row);
            vm.url = vm.getLinkUrl(field, row, scaStation);
        } catch (e){
            console.log(e);
        }

    }

    /**
     * When we have more hidden columns that are supposed to be in the link data we need to rebuild the link by fetching the data
     * This queries the server to get more data, that is why we don't do this by default, it is a rare situation and would be a waste of
     * resources to do for every link
     * @param field
     * @param row
     * @param scaStation
     */
    vm.buildJsonForSingleRecordOnHover = async (field, row, scaStation) => {
        try
        {
            if(vm.linkFactory.haveHiddenFields == true) {
                let allData = await scanStationService.getSingleRecordByRecordId(row['cartolytics_entry_row_id']);
                let fieldId = field.id;
                vm.linkFactory.loadLinkData(vm.scanStation.scanStationObj.scanStationFieldIdsToField, fieldId, allData.data.data);
                vm.url = vm.getLinkUrl(field, allData.data.data, scaStation);
                $scope.$apply();
            }

        } catch (e){
            console.log(e);
        }

    }

    /**
     * This will build the link url.
     *
     * @param field
     * @param row
     */
    vm.getLinkUrl = (field, row, scanStation) => {

        let linkType = vm.determineLinkType(field);
        let urlFieldSlugData = vm.linkFactory.getLinkData(row, field);

        // we only have to work out paramsToLoad when using the original bloc-link type parameter
        // for normal GET parameters in the URL we just append it on

        let returnValue = '';
        if(vm.linkType[field.field_slug] == 'bloc-link') {
            let uniqueModuleSlug = field.dropdownFromStationLookUp.uniqueModuleSlug;
            let stationId = field.dropdownFromStationLookUp.stationId;

            let paramsToLoad = {
                'uniqueModuleSlug' : uniqueModuleSlug,
                'stationId' : stationId,
                'urlFieldSlugData': urlFieldSlugData
            }
            returnValue = $state.href('bloc', paramsToLoad);
        } else if (vm.linkType[field.field_slug] == 'custom-url-with-json') {
            returnValue = field.linkDataCustomUrl + '?urlFieldSlugData=' + urlFieldSlugData;
        } else {
            returnValue = field.linkDataCustomUrl + '?' + urlFieldSlugData;
        }

        returnValue = vm.linkDataService.appendReturnDetails(field, scanStation, returnValue);
        returnValue = vm.linkDataService.appendFilterDataOptions(field, scanStation, returnValue);


        return returnValue;

    }

    /**
     * This function determines if we are showing the standard bloc link or a custom url with standard generated json, or custom url with normal url parameters
     * @param field
     * @returns {Promise<void>}
     */
    vm.determineLinkType = (field) => {
        let linkType = 'bloc-link';

        if((field.linkDataCustomUrl != null && field.linkDataCustomUrl != '' && (field.useStandardUrlParameters != 1))) {
            linkType = 'custom-url-with-json';
        } else if (field.linkDataCustomUrl != null && field.linkDataCustomUrl != '' && (field.useStandardUrlParameters == 1)) {
            linkType = 'custom-url-with-normal-parameters';
        }

        if(vm.linkType == null) {
            vm.linkType = [];
        }
        vm.linkType[field.field_slug] = linkType;
    }

    /**
     * This will be the init function of the linkData component
     * @param field
     */
    vm.initLinkData = (field, scanStation) => {

        // determining what is the field link type.
        vm.determineLinkType(field);

        // loading as type
        let linkData : LinkDataElements = {
            uniqueModuleSlug : '',
            stationId : '',
            urlFieldSlugData : '',
            href : '',
        }

        // setting the clean data for link data
        vm.linkDataService.linkData[field.field_slug] = linkData;
    }


    /**
     * This will be getting the return to set on the href
     * of a linkData field.
     *
     * @param field
     * @param scanStation
     */
    vm.loadBlocLink = (field, scanStation) => {

        // this is the case on service.cleanLinkData, after we submit we need to clean
        // vm.linkDataService.linkData and scanStation.linkedDataFieldJson, to avoid to have
        // default values triggering things
        if(vm.linkDataService.linkData == false){
            vm.linkDataService.linkData = {}
            scanStation.linkedDataFieldJson = {}

        } else  if(scanStation.linkedDataFieldJson != undefined && Object.keys(scanStation.linkedDataFieldJson).length > 0) {
            vm.linkDataService.loadBlocLink(field, scanStation, vm.linkType, $state);
        }


    }

    /**
     * This will be the init of the bloc link field.
     *
     * @param field
     * @param scanStation
     */
    vm.initBlocLink = (field, scanStation) => {
        if(scanStation.scanStationObj.stateName == 'edit-bloc') {
            return true;
        }

        // this will be starting the service.
        vm.linkDataService.initBlocLink(field, scanStation, vm.linkType, $state);
    }

    vm.openLink = function(fieldSlug) {
        var url = vm.linkDataService.getFieldHref(fieldSlug);
        window.open(url, vm.field.opensInNewTab == 1 ? '_blank' : '_self');
    };


}

export default LinkController;