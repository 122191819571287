import * as angular from 'angular';
import jQuery from "jquery";
import StatusService from "components/blocworx/services/status.service";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('MailAlertController', MailAlertController);

// Injection of each class
MailAlertController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'sound', 'status', 'Blocworx', 'MailAlertFactory', '$window'];

// Controller Constructor
function MailAlertController($rootScope, $scope, ScopeAndLocal, sound, status, Blocworx, MailAlertFactory, $window) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            status.runAfterSuccess(vm.runAfterSuccess, vm.scanStation);
            vm.showBulkUpload = [];

            vm.mailAlertConditions = {}
            vm.hours = MailAlertFactory.createHourArray();
            vm.days = MailAlertFactory.createDayArray();

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * TODO Raf : I needed to do my own one because runAfterSuccess didnt work on second submit
     */

    $rootScope.$on('cleanMailAlertFiles', async function (event, value) {
        await vm.runAfterSuccess();
        $scope.$apply()
    });

    vm.runAfterSuccess = async () => {

    }


    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    vm.triggerMailAlertUsingButton = async function(stationId, fieldIds, dataToAdd) {
        vm.pleasewait = true;
        try {
            await MailAlertFactory.triggerActions(stationId, fieldIds, dataToAdd);

            alert('Mail Successfully Sent');

            // we need to call rules after the mail has sent, this is to trigger other potential Blocworx functionality
            // we know this function only has an array with one field Id, for this reason we know to use the first and only value in the array
            let field = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldIds[0]];

            // the value will be a relatively meaningless value for now, we just want to call the rules in general
            // TODO the ability to set this value as a field parameter

            vm.scanStation.scanStationObj.dataToAdd[field.field_slug] = `value-${field.field_slug}`;

            await vm.scanStation.getRules('no-event', fieldIds[0], field.special_field_key, vm.scanStation.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);

            $scope.$apply();
        } catch (err) {
            alert('Something went wrong with this mail alert');
        }

        vm.pleasewait = false;
        $scope.$apply();
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    /**
     *  This will add a condition to the list of conditions
     * 
     * @param localFieldId  id of the local field
     * @param condition  condition to be added
     * @param valueToCompare  value to compare
     */
    vm.addCondition = function (localFieldId, condition, valueToCompare) {

        try {
            if (vm.fc.initialFieldParams.mailAlertConditions) {
                vm.mailAlertConditions = vm.fc.initialFieldParams.mailAlertConditions
            }
            
            MailAlertFactory.validate(localFieldId, condition, valueToCompare);
            vm.mailAlertConditions = MailAlertFactory.add(vm.mailAlertConditions, localFieldId, condition, valueToCompare);
            vm.fc.initialFieldParams.mailAlertConditions = vm.mailAlertConditions

        } catch (err) {
            alert(err);
        }
    }

    /**
     *  This function will remove a condition from the list of conditions
     * @param condition condition to be removed
     */
    vm.removeCondition = function (condition) {

        vm.mailAlertConditions = MailAlertFactory.remove(vm.mailAlertConditions, condition);
        vm.fc.initialFieldParams.mailAlertConditions = vm.mailAlertConditions
    }
}

export default MailAlertController;