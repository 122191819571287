import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('multiButtonField', multiButtonField);

/**
 * Service constructor.
 * @constructor
 */
function multiButtonField() {
    let service = this;


    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'multiButtonList': {
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please add at least one item to your button list',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'defaultButtonValue': {
                    'validation' : {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}