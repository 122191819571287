import * as angular from 'angular';
import { data } from "autoprefixer";

"use strict";

angular.module('FieldModule')
       .controller('RadioButtonController', RadioButtonController);

// Injection of each class
RadioButtonController.$inject = ['$scope', 'ScopeAndLocal', 'Auth'];

// Controller Constructor
function RadioButtonController($scope, ScopeAndLocal, Auth) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            vm.auth = Auth;

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            $scope.scanStation = vm.scanStation;

            ScopeAndLocal.startVariable(vm, $scope, 'field');
            $scope.field = vm.field;

            ScopeAndLocal.startVariable(vm, $scope, 'data');
            $scope.data = vm.data;

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            $scope.main = vm.main;

            ScopeAndLocal.startVariable(vm, $scope, 'row');
            $scope.row = vm.row;

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            $scope.fc = vm.fc;

            if(vm.field != null) {

                $scope.$watch(
                    () => vm.scanStation && vm.scanStation.stationDataForDropDown && vm.field && vm.scanStation.stationDataForDropDown[vm.field.field_slug],
                    (newValue, oldValue) => {

                        vm.scanStation.scanStationObj.dataToAdd[vm.field.field_slug] = null;

                        // Compare newValue and oldValue to determine if a change has occurred
                        if (!angular.equals(newValue, oldValue) && vm.scanStation.stationDataForDropDown[vm.field.field_slug] != null) {
                            this.scanStation.getRules('no-event', vm.field.id, vm.field.special_field_key, null, vm.field.fieldIndex, vm.field.field_slug);
                        }
                    }
                );
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will get the field that is trying to send the data and set to null
     * so the selected option is gone, making clean the radio button value.
     */
    vm.cleanRadioOptions = () => {
        vm.scanStation.scanStationObj.dataToAdd[vm.field.field_slug] = null;
        // this will only proceed if the "Force rules and actions when value becomes empty" is set, but this option is handled
        // in getRules, not here
        this.scanStation.getRules('no-event', vm.field.id, vm.field.special_field_key, '', vm.field.fieldIndex, vm.field.field_slug);
    }

    /**
     * This will get the rule for the label ID, so we can
     * match the label with input ID
     * @param label
     */
    vm.labelInputID = (label) => {

        let fieldName = vm.field.field_name.toLowerCase().replace(/\s/g, '_');
        let labelName = label.toLowerCase().replace(/\s/g, '_');
        return `${fieldName}_${labelName}`;
    }

    /**
     * This will check the conditions to show or hide the radio reset button.
     */
    vm.showCleanRadioOptions = () => {
        let valueToReturn = true;
        let dataToCheck = null

        if(vm.scanStation != undefined && vm.scanStation.scanStationObj != undefined
            && vm.scanStation.scanStationObj.dataToAdd != undefined){
            dataToCheck = vm.scanStation.scanStationObj.dataToAdd[vm.field.field_slug];
        }

        if(dataToCheck == null || dataToCheck == ''){
            valueToReturn = false;
        }
        return valueToReturn;
    }

    /**
     * This is the rule for showing or hiding the radio buttons
     * this will make sure that we have options to show
     * as well as after submit will check if the value is empty
     * trying to avoid to submit a previous data.
     *
     * @param option
     */
    vm.showRadioOptions = (option) => {

        let valueToReturn = true;

        // Step 1: Basic validation if the option contain an invalid value
        if(option == undefined && option == null){
            valueToReturn = false;
        }

        // Step 2: Check if we have the field set, so we cant get errors when this field isn't set
        if(vm.field != undefined) {

            // Step 5: After submit of the data, we must do some rules
            if(vm.scanStation.dataAddSuccess){

                // this case is for the fields that contain local field to match, so this value must be hidden after submit
                if(vm.field.localFieldMatchingFieldPairsArray != undefined){
                    valueToReturn = false;

                    // cleaning the stationDataForDropdown after the submit.
                    // TODO discuss with Raf why this was added and what it was meant to do, I ran several tests and its ok without it but breaking things with it
                   // vm.scanStation.stationDataForDropDown[vm.field.field_slug] = [];
                }
            }

        }

        return valueToReturn;

    }

}

export default RadioButtonController;