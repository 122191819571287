import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('ScanStationFieldController', ScanStationFieldController);

// Injection of each class
ScanStationFieldController.$inject = ['$scope', 'ScopeAndLocal', 'Auth'];

// Controller Constructor
function ScanStationFieldController($scope, ScopeAndLocal, Auth) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            vm.auth = Auth;

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            $scope.fc = vm.fc;

            ScopeAndLocal.startVariable(vm, $scope, 'grid');
            $scope.grid = vm.grid;

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            $scope.scanStation = vm.scanStation;

            ScopeAndLocal.startVariable(vm, $scope, 'field');
            $scope.field = vm.field;

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            $scope.main = vm.main;

            ScopeAndLocal.startVariable(vm, $scope, 'data');
            $scope.data = vm.data;

        } catch (e) {
            console.log(e);
        }
    }

}

export default ScanStationFieldController;