import * as angular from 'angular';
import * as $ from "jquery";
import {type} from "os";

"use strict";

angular
    .module('FieldModule')
    .factory('fieldValidation', fieldValidation);


fieldValidation.$inject = ['fieldService'];


/**
 * Object that will create a Field Validation Factory, all methods of factory
 * will be available from outside.
 */
function fieldValidation(fieldService) {

    let factory = this;
    factory.validationResponse = '';


    /**
     * This will set the validation response of this factory,
     * we have a basic rule of we cant get double messages. so to prevent
     * that from happening we must check if is included before add to it
     * avoiding any issue with double messages.
     *
     * @param validationResponseSent
     */
    factory.setValidationResponse = (validationResponseSent) => {

        // if we cant see the same content inside we shouldn't do this.
        if(!factory.validationResponse.includes(validationResponseSent)){
            factory.validationResponse+=validationResponseSent + '\n';
        }
    }

    /**
     * By using parameterObject, initialFieldParamsProvided and the key we can
     * check 2 types of validation:
     *
     *  - listMustHaveOne
     *    This will check the size of the initialFieldParamsProvided length == 0
     *
     *  - stringMustNotBeEmpty
     *    This will check the string of the initialFieldParamsProvided is not '' or null
     *
     * @param parameterObject
     * @param initialFieldParamsProvided
     * @param parameterKey
     */
    factory.validationProcess = (parameterObject, initialFieldParamsProvided, parameterKey) => {

        // checks that validation is required
        if (parameterObject.validation.required == true){

            // checking for must have one, it checks if the provided params has length == 0
            if (parameterObject.validation.requiredType == 'listMustHaveOne') {
                if (initialFieldParamsProvided[parameterKey].length == 0) {
                    factory.setValidationResponse(parameterObject.validation.validationResponse);
                }
            }

            // checking that must not be empty, checks if the privided params is equal to '' (empty string) or the value is null
            if (parameterObject.validation.requiredType == 'stringMustNotBeEmpty') {
                if (initialFieldParamsProvided[parameterKey] == '' || initialFieldParamsProvided[parameterKey] == null ) {
                    factory.setValidationResponse(parameterObject.validation.validationResponse);
                }
            }

        }

    }

    /**
     * This is a main validation for the fields, this will be triggered
     * when a new field is added or when we are editing some field.
     *
     * @param fieldType
     * @param intialFieldParamsProvided
     */
    factory.validateInitialFieldParams = async function (fieldType, intialFieldParamsProvided) {

        let defaultInitialFieldParams = await fieldService.getInitialParams(fieldType);

        factory.validationResponse = '';

        // loop through main parameters and check for required parameters
        // Inside this loop we must repeat the same process to check required sub parameters
        // No field parameters should go deeper than one level
        angular.forEach(defaultInitialFieldParams, function (parameterObject, parameterKey) {

                if (typeof(intialFieldParamsProvided[parameterKey]) != 'undefined' ) {

                    factory.validationProcess(parameterObject,intialFieldParamsProvided, parameterKey);

                    // repeat the same for sub parameters
                    if(typeof(parameterObject.subParameters) != 'undefined') {

                        angular.forEach(parameterObject.subParameters, function(subParameterObject, subParameterKey) {

                            if (typeof(intialFieldParamsProvided[parameterKey][subParameterKey]) != 'undefined' ) {

                                factory.validationProcess(subParameterObject,intialFieldParamsProvided[parameterKey], subParameterKey);

                            } else if (subParameterObject.validation.required == true) {
                                factory.setValidationResponse(subParameterObject.validation.validationResponse);
                            }

                        });

                    }

                } else if (parameterObject.validation.required == true) {
                    factory.setValidationResponse(parameterObject.validation.validationResponse);
                }

        });

        // true or a message with errors
        return factory.validationResponse != '' ? factory.validationResponse : true;

    }

    return factory;
}

