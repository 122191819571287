import * as angular from 'angular';
import FieldController from "./field.controller";

"use strict";
angular.module('FieldModule')
       .controller('CrossBlocDataUpdatesController', CrossBlocDataUpdatesController);

// Injection of each class
CrossBlocDataUpdatesController.$inject = [ 'Configuration', '$scope', 'CrossBlocDataUpdatesFieldFactory', '$window', 'ResultsBoxService'];

// Controller Constructor
function CrossBlocDataUpdatesController( Configuration, $scope, CrossBlocDataUpdatesFieldFactory, $window, ResultsBoxService) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            vm.version = Configuration.getVersion();
            $scope.scanStation = vm.scanStation;
            $scope.field = vm.field;

        } catch (e) {
            console.log(e);
        }
    }

    vm.triggerAction = async function(fieldId, dataToAdd, sourceRecordId) {
       
        if(vm.triggerCrossBlocButtonClick == null) {
            vm.triggerCrossBlocButtonClick = [];
        }
        
        vm.triggerCrossBlocButtonClick['_'+ fieldId] = true;

        let actionResponse = await CrossBlocDataUpdatesFieldFactory.triggerActions([fieldId], dataToAdd, sourceRecordId);
        let field = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldId];
        vm.scanStation.scanStationObj.dataToAdd[field.field_slug] = actionResponse.data.data['fieldId_'+fieldId];

        if(field.whenActionHappens == 'on-button-click') {
            await vm.applyPostButtonClickOrPopUpCrossBlocActions(field, fieldId);
        }

        $scope.$apply();

        vm.triggerCrossBlocButtonClick['_'+ fieldId] = false;
    }


    /**
     * This code inserts local matching fields into a pop up by integrating with the grid
     * @param crossBlocField
     */
    vm.openPopUpOrEmbeddedBloc = async function (crossBlocField) {

        try {
            vm.scanStation.gridPopUpObject = {};

            if(crossBlocField.dataManipulationOption != 'view-record-only') {
                vm.scanStation.gridPopUpObject.gridPopUpTriggerSource = 'triggering-cross-bloc-action';
            } else {
                vm.scanStation.gridPopUpObject.gridPopUpTriggerSource = 'viewing-cross-bloc-record';
            }
            vm.scanStation.gridPopUpObject.sourceField = crossBlocField;
            vm.scanStation.gridPopUpObject.passThroughDataFieldSlugPairs = {};

            // in this case it might be a pop up or an embedded bloc
            vm.scanStation.gridPopUpObject.viewType = crossBlocField.whenActionHappens;
            if(vm.scanStation.gridPopUpObject.viewType == 'open-popup-with-bloc') {
                vm.scanStation.gridPopUpObject.popUpOrEmbedded = 'popup';
            }

            let crossBlocLocalFieldJsonParsed = vm.scanStation.scanStationObj.scanStationFieldIdsToField[crossBlocField.id].localFieldMatchingFieldPairsToUpdate;

            for (var key in crossBlocLocalFieldJsonParsed) {
                let fieldPair  = crossBlocLocalFieldJsonParsed[key];
                vm.scanStation.gridPopUpObject.passThroughDataFieldSlugPairs[`_${fieldPair.localFieldId}`] = `_${fieldPair.remoteFieldId}`;
            }

            let singleRecordEntryData = null;
            let recordId = null;
            if(crossBlocField.dataManipulationOption != 'add-new-record' && crossBlocField.dataManipulationOption != 'delete-record') {

                // this code insures we only send over the data we are using to search rather than all the blocs data which would cause performance issues

                let dataToAddToSendOver = {};
                // loop through crossBlocField.localFieldMatchingFieldPairsToCheckAgainst and just console log the values
                for (var key in crossBlocField.localFieldMatchingFieldPairsToCheckAgainst) {
                    let fieldPair  = crossBlocField.localFieldMatchingFieldPairsToCheckAgainst[key];
                    // using fieldPair.localFieldId get the field from the scanStationFieldIdsToField
                    let localFieldSlug = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldPair.localFieldId].field_slug;
                    // if localFieldSlug is in the dataToAdd then add it to the dataToAddToSendOver
                    if(vm.scanStation.scanStationObj.dataToAdd[localFieldSlug] != null) {
                        dataToAddToSendOver[localFieldSlug] = vm.scanStation.scanStationObj.dataToAdd[localFieldSlug];
                    }

                }

                let singleRecordEntryDataQuery = await CrossBlocDataUpdatesFieldFactory.getScanStationDataRecordByFieldMatchingPairs(crossBlocField.id, dataToAddToSendOver, crossBlocField.localFieldMatchingFieldPairsToCheckAgainst, crossBlocField.crossBlocDataStationToEdit.stationId);

                singleRecordEntryData  = singleRecordEntryDataQuery.data.data;

                recordId = singleRecordEntryDataQuery.data.recordId;
            }

            await vm.scanStation.openRemoteScanStationPopUp(crossBlocField.crossBlocDataStationToEdit.stationId, recordId, vm.scanStation.gridPopUpObject.gridPopUpTriggerSource, singleRecordEntryData);

            $window.addEventListener('message', async function (data) {
                try {

                    if(data.data != null && data.data == 'afterCrossBlocPopUpSubmit') {
                        await vm.applyPostButtonClickOrPopUpCrossBlocActions(vm.scanStation.gridPopUpObject.sourceField, vm.scanStation.gridPopUpObject.sourceField.id)

                        vm.scanStation.gridPopUpObject = {};
                    }

                } catch (e) {

                }
            });


        } catch (exception) {

            console.log(exception);

        }
    }

    vm.applyPostButtonClickOrPopUpCrossBlocActions = async (field, fieldId) => {

        await vm.scanStation.getRules('no-event', fieldId, field.special_field_key, vm.scanStation.scanStationObj.dataToAdd[field.field_slug], field.domIndex, field.field_slug);
        await vm.scanStation.updateDropDownsRadiosAutoCompletesForAllFields(field);

        if(field.fieldsToRefreshAfterCompletion != null) {
            await ResultsBoxService.callTriggerResultsBoxViaForcedRefreshList(field.fieldsToRefreshAfterCompletion);
        }


        if(field.clearFieldsAfterButtonClick != null) {

            for (let key in field.clearFieldsAfterButtonClick) {
                if (field.clearFieldsAfterButtonClick.hasOwnProperty(key)) {

                    let fieldIdToClear = field.clearFieldsAfterButtonClick[key];

                    let fieldToClear = vm.scanStation.scanStationObj.scanStationFieldIdsToField[fieldIdToClear];

                    vm.scanStation.scanStationObj.dataToAdd[fieldToClear.field_slug] = '';

                    // in most cases this should do nothing due it being a blank value, however certain parameters may allow the function to complete
                    await vm.scanStation.getRules('no-event', fieldToClear.id, fieldToClear.special_field_key, vm.scanStation.scanStationObj.dataToAdd[fieldToClear.field_slug], fieldToClear.domIndex, fieldToClear.field_slug);
                }
            }
        }


        if(field.clickOnFieldAfterButtonClick != null && field.clickOnFieldAfterButtonClick != '') {

            let inputField = document.querySelector(`input[data-field-id="${field.clickOnFieldAfterButtonClick}"]`);
            // Focus on the element if it exists
            if (inputField) {
                (inputField as HTMLElement).focus();
                (inputField as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }

        if(vm.scanStation.gridPopUpObject != null && vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp != null) {
            for (var key in vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp) {
                let fieldSlug = vm.scanStation.gridPopUpObject.fieldSlugsUpdatedFromPopUp[key];
                let field = vm.scanStation.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
                await vm.scanStation.getRules('no-event', field.id, field.special_field_key, vm.scanStation.scanStationObj.dataToAdd[fieldSlug], field.domIndex, fieldSlug);
            }
        }

        $scope.$apply();
    }

}

export default CrossBlocDataUpdatesController;
