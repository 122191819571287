import * as angular from 'angular';

"use strict";

angular
    .module('FieldModule')
    .factory('DataFromStationFieldsFactory', DataFromStationFieldsFactory);


DataFromStationFieldsFactory.$inject = ['fieldService'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function DataFromStationFieldsFactory(fieldService) {
    let factory = this;

    /**
     * Method responsible for building and editing the local matching fields Array
     * @param localFieldMatchingFieldPairsArray
     * @param action
     * @param localField
     * @param remoteField
     * @param index
     * @returns {*}
     */
    factory.addOrRemoveDataFromAnotherStationMatchingFieldPairs = function (localFieldMatchingFieldPairsArray, action, localField, remoteField, index = null) {

        if((localField == '' || typeof localField == 'undefined') && action == 'add') {
            alert('Please Select a local Field');
            return localFieldMatchingFieldPairsArray;
        }

        if((remoteField == '' || typeof remoteField == 'undefined') && action == 'add') {
            alert('Please Select a remote field it must match');
            return localFieldMatchingFieldPairsArray;
        }

        if(typeof localFieldMatchingFieldPairsArray == 'undefined') {
            localFieldMatchingFieldPairsArray = {};
        }

        if(action == 'add') {
            localField = JSON.parse(localField);
            remoteField = JSON.parse(remoteField);
            localFieldMatchingFieldPairsArray[Object.keys(localFieldMatchingFieldPairsArray).length] = {
                'localFieldId' : localField.id,
                'remoteFieldId' : remoteField.id,
                'localFieldName' : localField.field_name,
                'remoteFieldName' : remoteField.field_name
            };

        } else {
            delete localFieldMatchingFieldPairsArray[index];
            // build new object with fresh keys to keep it in sync
            var fieldListForLoop = localFieldMatchingFieldPairsArray;
            localFieldMatchingFieldPairsArray = {};
            var i = 0;
            angular.forEach(fieldListForLoop, function(value) {
                localFieldMatchingFieldPairsArray[i] = value;
                i++;
            });
        }



        return localFieldMatchingFieldPairsArray;

    }

    factory.canGetLocalFieldMatchingFieldPairs = async function(field_id, dropdownFilteredDataFields) {
        var localFieldMatchingFieldPairsToCheck = [];
        angular.forEach(dropdownFilteredDataFields, function(value) {
            if(typeof value.localFieldMatchingFieldPairs != 'undefined' && value.localFieldMatchingFieldPairs != null) {
                angular.forEach(value.localFieldMatchingFieldPairs, function(matchingFieldPair) {
                    if(matchingFieldPair.localFieldId == field_id) {
                        localFieldMatchingFieldPairsToCheck.push(value);
                    }
                });
            }
        });

        return localFieldMatchingFieldPairsToCheck;
    }

    /**
     * This function removes items from the dropdown/autocomplete/radio button list based on matching data
     * @param field_id
     * @param dropdownFilteredDataFields
     * @returns {Promise<any[]>}
     */
    factory.canGetLocalFieldExclusionMatchingFieldPairs = async function(field_id, dropdownFilteredDataFields) {
        var localFieldExclusionMatchingFieldPairsToCheck = [];
        angular.forEach(dropdownFilteredDataFields, function(value) {
            if(typeof value.localFieldExclusionMatchingFieldPairsArray != 'undefined' && value.localFieldExclusionMatchingFieldPairsArray != null) {
                angular.forEach(value.localFieldExclusionMatchingFieldPairsArray, function(matchingFieldPair) {
                    if(matchingFieldPair.localFieldId == field_id) {
                        localFieldExclusionMatchingFieldPairsToCheck.push(value);
                    }
                });
            }
        });

        return localFieldExclusionMatchingFieldPairsToCheck;
    }

    factory.buildDropDownFilterDataFields = function(field) {

        let dropDownFilterDataFields = {};

        dropDownFilterDataFields['fieldListSlug'] = field.field_slug;
        dropDownFilterDataFields['dropdownFromStationLookUp'] = field.dropdownFromStationLookUp;
        if(field.dataListDraggedToCalendarBlocAndFieldSelection != null) {
        	dropDownFilterDataFields['dropdownFromStationLookUp'] = field.dataListDraggedToCalendarBlocAndFieldSelection;
        }

        if(typeof field.dropdownFromStationReverseOrder != 'undefined') {
            dropDownFilterDataFields['dropdownFromStationReverseOrder'] = field.dropdownFromStationReverseOrder;
        } else {
            dropDownFilterDataFields['dropdownFromStationReverseOrder'] = 0;
        }

        if(typeof field.dropdownFromStationOrder != 'undefined') {
            dropDownFilterDataFields['dropdownFromStationOrder'] = field.dropdownFromStationOrder;
        } else {
            dropDownFilterDataFields['dropdownFromStationOrder'] = 0;
        }

        if(typeof field.dropDownFromStationExcludeMatchingDataFromAnotherFieldData != 'undefined') {
            dropDownFilterDataFields['dropDownFromStationExcludeMatchingDataFromAnotherFieldData'] = field.dropDownFromStationExcludeMatchingDataFromAnotherFieldData;
        } else {
            dropDownFilterDataFields['dropDownFromStationExcludeMatchingDataFromAnotherFieldData'] = null;
        }

        if(typeof field.dropdownFromStationOnlyDataAfterDate != 'undefined') {
            dropDownFilterDataFields['dropdownFromStationOnlyDataAfterDate'] = field.dropdownFromStationOnlyDataAfterDate;
        } else {
            dropDownFilterDataFields['dropdownFromStationOnlyDataAfterDate'] = null;
        }

        if(typeof field.localFieldMatchingFieldPairs != 'undefined') {
            dropDownFilterDataFields['localFieldMatchingFieldPairs'] = field.localFieldMatchingFieldPairs;
        } else {
            dropDownFilterDataFields['localFieldMatchingFieldPairs'] = null;
        }

        if(typeof field.localFieldExclusionMatchingFieldPairsArray != 'undefined') {
            dropDownFilterDataFields['localFieldExclusionMatchingFieldPairsArray'] = field.localFieldExclusionMatchingFieldPairsArray;
        } else {
            dropDownFilterDataFields['localFieldExclusionMatchingFieldPairsArray'] = null;
        }

        return dropDownFilterDataFields;


    }

    factory.setInitialFieldParametersFromEdit = function (fieldParameters) {

        var parametersToReturn = {};
        var parametersAvailable = [
            'localFieldMatchingFieldPairs',
            'localFieldMatchingFieldPairsArray',
            'localFieldExclusionMatchingFieldPairsArray',
            'dropdownFromStationOnlyDataAfterDate',
            'dropDownFromStationExcludeMatchingDataFromAnotherFieldData',
            'dropdownFromStationReverseOrder',
            'dropdownFromStationOrder',
            'dropdownFromStationLookUp',
            'valuesDisplaySetting'
        ]
        angular.forEach(fieldParameters, function(fieldParam) {
            if(parametersAvailable.includes(fieldParam.scan_station_field_parameter)) {
                try {
                    parametersToReturn[fieldParam.scan_station_field_parameter] = JSON.parse(fieldParam.parameter_value);
                } catch(e) {
                    parametersToReturn[fieldParam.scan_station_field_parameter] = fieldParam.parameter_value;
                }
            }
        });

        return parametersToReturn;
    }

    return factory;
}

