import * as angular from 'angular';

"use strict";

angular
    .module('FieldModule')
    .factory('LinkFieldFactory', linkFieldFactory);


linkFieldFactory.$inject = [];


/**
 * Object that will create a Link Field Factory, all methods of factory
 * will be available from outside.
 */
function linkFieldFactory() {

    let factory = this;
    factory.linkData = {}

    /**
     * This function will be responsible to add a field Link
     *
     * @param linkArray
     * @param action
     * @param field
     * @param index
     */
    factory.addNewFieldLink = (linkArray, fieldId = null) => {

        if(fieldId == null) {
            alert('Please Select a Field');
            return linkArray;
        }

        if(typeof linkArray == 'undefined' || (typeof linkArray != 'undefined' && linkArray.length == 0) || (typeof linkArray === 'object' && Object.keys(linkArray).length == 0)) {
            linkArray = [];
        }

        // rebuild to an array if its an object
        if(typeof linkArray === 'object' && Object.keys(linkArray).length > 0) {
            let temporaryArray = [];

            angular.forEach(linkArray, function(value) {
                temporaryArray.push(value);
            })

            linkArray = temporaryArray;
        }

        linkArray.push(fieldId);

        return linkArray;
    }

    /**
     * This will be responsible to remove a field link.
     *
     * @param linkArray
     * @param action
     * @param field
     * @param index
     */
    factory.removeFieldLink = (linkArray, index = null) => {

        if(index == null) {
            alert('Please Select a field');
            return linkArray;
        }

        delete linkArray[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = linkArray;
        linkArray = [];
        let i = 0;
        angular.forEach(fieldListForLoop, function(value) {
            linkArray[i] = value;
            i++;
        });
        return linkArray;
    }

    /**
     * This will be responsible for building the json string that gets added to the end of the link in the results/records
     *
     * @param scanStationFieldIdsToField
     * @param fieldId
     * @param data
     */
    factory.buildJsonForSingleRecord = (scanStationFieldIdsToField, fieldId, data) => {
        let jsonObject = {};
        angular.forEach(scanStationFieldIdsToField[fieldId].fieldLinkArray, function (fieldIdWithData) {
            jsonObject[scanStationFieldIdsToField[fieldIdWithData].field_slug] = data[scanStationFieldIdsToField[fieldIdWithData].field_slug];
        });

        let jsonString = JSON.stringify(jsonObject);

        return jsonString;

    }

    /**
     * Method that load the link data variable.
     *
     * @param scanStationFieldIdsToField
     * @param fieldId
     * @param row
     */
    factory.loadLinkData = (scanStationFieldIdsToField, fieldId, row) => {

        let jsonObject = {};

        factory.fieldsNotVisibleInColumns = [];

        // building the json object
        angular.forEach(scanStationFieldIdsToField[fieldId].fieldLinkArray, function (fieldIdWithData) {
            jsonObject[scanStationFieldIdsToField[fieldIdWithData].field_slug] = row[scanStationFieldIdsToField[fieldIdWithData].field_slug];

            if(row[scanStationFieldIdsToField[fieldIdWithData].field_slug] == null && factory.hiddenFieldsDefined == null) {
                factory.fieldsNotVisibleInColumns.push(scanStationFieldIdsToField[fieldIdWithData].field_slug);
            }

        });


        if(factory.fieldsNotVisibleInColumns.length > 0) {
            // factory.haveHiddenFields never goes back to false, we use this to determine if we are going to continue to load extra hidden field data
            factory.haveHiddenFields = true;
        }

        let jsonString = JSON.stringify(jsonObject);

        if(scanStationFieldIdsToField[fieldId].useStandardUrlParameters == 1) {
            jsonString = new URLSearchParams(jsonObject).toString();
        }

        if( factory.fieldsNotVisibleInColumns.length>0) {
            factory.hiddenFieldsDefined
        }

        factory.linkData['row_' + row.cartolytics_entry_row_id + '_field_id_' + fieldId] = jsonString;
    }

    /**
     *  Method that get data from link data variable.
     * @param row
     * @param field
     */
    factory.getLinkData = (row, field) => {
        return factory.linkData['row_' + row.cartolytics_entry_row_id + '_field_id_' + field.id];
    }

    /**
     * This will be responsible for building the json string that gets added to the end of the link on the form itself
     * TODO remove reference to JSON as now we have more options
     * @param scanStationFieldIdsToField
     * @param dataToAdd
     */
    factory.rebuildLinkedDataJson = (linkDataFields, scanStationFieldIdsToField, dataToAdd) => {

        let linkedDataFieldJson = [];
        angular.forEach(linkDataFields, function(field) {
           if(field.field_type == 'link-data-field') {

               linkedDataFieldJson['key_' + field.id] = {};

               if(scanStationFieldIdsToField[field.id] != null && scanStationFieldIdsToField[field.id].fieldLinkArray != null) {
                   angular.forEach(scanStationFieldIdsToField[field.id].fieldLinkArray, function (fieldIdWithData) {
                       linkedDataFieldJson['key_' + field.id][scanStationFieldIdsToField[fieldIdWithData].field_slug] = dataToAdd[scanStationFieldIdsToField[fieldIdWithData].field_slug];
                   });
               }

               if(field.useStandardUrlParameters == 1) {
                   linkedDataFieldJson['key_' + field.id] = new URLSearchParams(linkedDataFieldJson['key_' + field.id]).toString();
               } else {
                   linkedDataFieldJson['key_' + field.id] = JSON.stringify(linkedDataFieldJson['key_' + field.id]);
               }
           }
        });

        return linkedDataFieldJson;
    }


    return factory;
}



