import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import MultiButtonController from "./controllers/multiButton.controller";

"use strict";

class MultiButtonComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/multiButton';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '=',
        field: '=',
        data: '=',
        main: '=',
        row: '=',
        fc: '=',
        last: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = MultiButtonController;

    // This will set an alias
    public controllerAs: string = 'mbc';
}

angular.module('FieldModule').component('multiButton', <StandardComponent> new MultiButtonComponent);